$base-color: #369;
$base-text-color: #000;
$base-background-color: #302e2e;

$header-color: #fff;
$header-background: $base-color;

$list-title-color: #fff;
$list-title-background: $base-color;
$list-content-color: #000;

$list-today-text-color: #a51313;
$list-today-background-color: #a51313;
$list-today-title-color: #fff;

$row-controls-color: #fff;

$droppable-area-color: red;

$todo-line-border-color: #cecece;
* {
  font-family: Inter, serif;
}
html,
body {
  height: 100%;
  background: $base-background-color;
  font-family: Inter, sans-serif;
}
h1,
h2,
h3 {
  font-family: Inter, serif;
  font-weight: normal;
}
#root {
  height: 100%;
}
.App {
  height: 100%;
  display: grid;
  flex-direction: column;
  grid-template-rows: 50px 3fr 2px 3fr;
  background: $base-background-color;
}
.mid-screen-divider {
  //background: $base-color;
  align-items: center;
  height: 8px;
  justify-content: center;
  text-align: center;
  display: flex;
  content: none;
}
.loginPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  .logo {
    max-height: 30px;
    display: flex;
    flex-direction: row;
    align-content: center;
    height: 30vmin;
    margin-bottom: 24px;
    img {
      max-height: inherit;
      filter: invert(100%);
    }
    span {
      font-size: 24px;
      color: $header-color;
    }
  }
  button {
    width: 10%;
    height: 64px;
    cursor: pointer;
  }
}
.lists-container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 20px repeat(5, 3fr) 20px;
  grid-template-rows: 100%;
  align-items: stretch;
  margin: 0 10px 10px;

  .row-controls {
    align-items: center;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    color: $row-controls-color;
    font-size: 92px;
    text-align: center;
    padding-top: 12px;
    span.material-icons {
      margin-bottom: 12px;
      cursor: pointer;
    }
  }
}
.list-container {
  display: grid;
  grid-template-rows: min-content 1fr;
  color: $base-text-color;
  font-size: 14px;
  border-radius: 8px;
  background: linear-gradient(to bottom left, #dedede, #fefefe);
  box-shadow: 0px 2px 6px -4px rgba(0, 0, 0, 0.75);
  .list-title {
    margin: 0;
    color: $list-title-color;
    padding: 16px 8px;
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    flex: 1;
    overflow: hidden;
    background: $list-title-background;
    > span {
      display: flex;
      flex-direction: row;
      justify-content: center;

      font-size: 24px;
      &.list-title-calendar-date {
        font-size: 14px;
        font-weight: normal;
        padding: 8px 0;
      }
    }

    &.isToday {
      color: $list-today-title-color;
      background: $list-today-background-color;
    }
    .list-title-controls {
      display: none;
      padding-left: 8px;
    }
    &:hover {
      .list-title-controls {
        display: flex;
        .material-icons {
          font-size: 18px;
        }
      }
    }
  }
  .list-content {
    flex: 100;
    display: flex;
    flex-direction: column;
    margin: 12px 8px 8px;
    background-image: repeating-linear-gradient(
      transparent,
      transparent 26px,
      $todo-line-border-color 27px,
      $todo-line-border-color 28px,
      transparent 28px
    );
    .task {
      //border: solid 1px red;
      text-align: left;
      //border-bottom: dotted 1px gray;
      display: flex;
      line-height: 28px;
      .task-description {
        flex: 1;
      }
      .task-controls {
        flex: 0;
        text-align: right;
        cursor: pointer;
        display: none;
        .material-icons {
          font-size: 16px;
        }
      }
      &:hover {
        cursor: pointer;
        .task-controls {
          display: inline-block;
        }
      }
      &.done {
        text-decoration: line-through;
        opacity: 0.5;
      }
    }
    .list-new-item-input {
      border: none;
      background: transparent;
      outline: 0;
      display: block;
      width: 100%;
      line-height: 26px;
      color: $base-text-color;
    }
  }
  &.isToday {
    color: $list-today-text-color;
    .list-new-item-input {
      color: $list-today-text-color;
    }
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 4s ease-in-out;
  }
}

.App-header {
  display: flex;
  max-height: 40px;
  min-height: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  border-bottom: dotted 2px black;
  background: $header-background;
  color: $header-color;
  padding: 0 10px;
  .header-menu {
    flex: 9;
    align-items: end;
    display: flex;
    flex-direction: row-reverse;
  }
  .logo {
    flex: 0;
    max-height: 30px;
    display: flex;
    flex-direction: row;
    align-content: center;
    height: 30vmin;
    img {
      max-height: inherit;
      filter: invert(100%);
    }
    span {
      font-size: 24px;
    }
  }

  .profile {
    font-size: 18px;
    color: $header-color;
    line-height: 30px;
    text-align: center;

    .profile-avatar {
      height: 30px;
      overflow: hidden;
      border-radius: 50%;
      align-content: center;
      line-height: 30px;

    }
  }
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0px);
  }
}

.droppable-area-active {
  background: $droppable-area-color;
  opacity: 0.4;
}

.material-icons {
  cursor: pointer;
}
